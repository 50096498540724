import { ReactElement } from 'react'
import generateUid from '@utils/generate-uid'

export default function WordIcon(): ReactElement {
  const pathId = `pattern-id-${generateUid()}`
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${pathId})`}>
        <path
          d="M13.6677 -3.69174e-06C13.6591 0.00343381 13.6488 0.00859008 13.6402 0.0137463L1.32019 2.35125C1.1105 2.39078 0.959251 2.5764 0.962689 2.79125V19.2087C0.959251 19.4236 1.1105 19.6092 1.32019 19.6487L13.6402 21.9862C13.7674 22.0103 13.8997 21.9777 14.0011 21.8952C14.1008 21.8127 14.161 21.6906 14.1627 21.56V19.36H21.6427C22.1239 19.36 22.5227 18.9612 22.5227 18.48V3.52C22.5227 3.03875 22.1239 2.64 21.6427 2.64H14.1627V0.439996C14.1644 0.312809 14.1094 0.192496 14.0149 0.108278C13.9203 0.0240588 13.7932 -0.0154725 13.6677 -3.69174e-06ZM13.2827 0.962496V2.9425C13.2569 3.02843 13.2569 3.11781 13.2827 3.20375V18.8375C13.2707 18.8959 13.2707 18.9578 13.2827 19.0162V21.0375L1.84269 18.8512V3.14875L13.2827 0.962496ZM14.1627 3.52H21.6427V18.48H14.1627V16.28H20.3227V15.4H14.1627V12.76H20.3227V11.88H14.1627V9.68H20.3227V8.8H14.1627V6.6H20.3227V5.72H14.1627V3.52ZM2.99769 6.88875L4.67519 15.1112H6.32519L7.41144 9.845C7.46128 9.60265 7.50769 9.28468 7.53519 8.91H7.54894C7.56097 9.25031 7.58847 9.57 7.64519 9.845L8.70394 15.1112H10.2989L11.9902 6.88875H10.5327L9.65269 12.375C9.60113 12.6844 9.56847 12.9937 9.55644 13.2962H9.54269C9.51347 12.9095 9.48425 12.6191 9.44644 12.4162L8.40144 6.88875H6.86144L5.70644 12.3475C5.63253 12.6964 5.58097 13.0161 5.56894 13.31H5.54144C5.52425 12.9164 5.49847 12.595 5.45894 12.3612L4.56519 6.88875H2.99769Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="22" height="22" fill="white" transform="translate(0.962769)" />
        </clipPath>
      </defs>
    </svg>
  )
}
