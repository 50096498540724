export default function TwitterIcon(): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14212_228779)">
        <rect width="24" height="24" rx="12" fill="#444444" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1969 7.68597C18.6675 7.92115 18.0977 8.07954 17.5002 8.15106C18.1102 7.78532 18.5787 7.20648 18.799 6.51677C18.2288 6.85562 17.5957 7.10089 16.9233 7.23384C16.3843 6.6598 15.6158 6.30078 14.7668 6.30078C13.1354 6.30078 11.8126 7.62357 11.8126 9.25498C11.8126 9.48633 11.839 9.71191 11.8894 9.92838C9.43385 9.80551 7.25719 8.62911 5.80001 6.8417C5.54563 7.278 5.40019 7.78533 5.40019 8.32673C5.40019 9.35146 5.92096 10.2557 6.71435 10.7856C6.22958 10.7702 5.77457 10.6373 5.37572 10.416V10.453C5.37572 11.8847 6.39469 13.0784 7.74532 13.3506C7.49765 13.4177 7.23655 13.4542 6.96729 13.4542C6.77674 13.4542 6.59148 13.4355 6.41101 13.4009C6.78682 14.5745 7.87827 15.4288 9.17082 15.4528C8.16001 16.2448 6.88617 16.7175 5.50195 16.7175C5.26292 16.7175 5.02774 16.7036 4.79688 16.6758C6.10431 17.5138 7.657 18.0034 9.32537 18.0034C14.7591 18.0034 17.7306 13.5017 17.7306 9.59816C17.7306 9.47001 17.7282 9.34234 17.7219 9.21611C18.2998 8.79854 18.8009 8.27825 19.1969 7.68597Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14212_228779">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
