import { ReactElement } from 'react'

export default function UnknownFileIcon(): ReactElement {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9628 5.21246C22.9628 5.01668 22.865 4.82091 22.6694 4.67408L17.1613 0.244716C16.9657 0.0734149 16.6724 0 16.3791 0H2.69018C1.74499 0 0.962769 0.587319 0.962769 1.297V20.703C0.962769 21.4127 1.74499 22 2.69018 22H21.2354C22.1805 22 22.9628 21.4127 22.9628 20.703V5.21246ZM21.2354 21.0211H2.69018C2.46203 21.0211 2.26647 20.8743 2.26647 20.703V1.297C2.26647 1.1257 2.46203 0.978866 2.69018 0.978866H16.1509V3.89099C16.1509 4.67408 16.9983 5.31034 18.0413 5.31034H21.6591V20.703C21.6591 20.8743 21.4635 21.0211 21.2354 21.0211Z"
        fill="black"
      />
      <path
        d="M11.6176 15H10.8824C10.2941 15 10 15.25 10 15.75V16.25C10 16.75 10.2941 17 10.8824 17H11.6176C12.2059 17 12.5 16.75 12.5 16.25V15.75C12.5 15.25 12.2059 15 11.6176 15Z"
        fill="black"
      />
      <path
        d="M11.6588 7C10.5896 7 9.78771 7.3271 9.31994 7.65421C8.91899 7.91589 8.91899 8.24299 9.18629 8.63551L9.52041 9.15888C9.78771 9.5514 10.1887 9.5514 10.6564 9.35514C10.8569 9.2243 11.1242 9.15888 11.3915 9.15888C11.9261 9.15888 12.3939 9.48598 12.3939 9.94392C12.3939 10.9907 10.1887 11.6449 10.1887 13.0841V13.215C10.1887 13.7383 10.456 14 10.9905 14H11.7924C12.2602 14 12.3939 13.8692 12.5275 13.4766L12.5943 13.2804C12.8616 12.2336 15 11.7103 15 9.61682C14.9332 7.91589 13.2626 7 11.6588 7Z"
        fill="black"
      />
    </svg>
  )
}
