import { ReactElement } from 'react'
import generateUid from '@utils/generate-uid'

export default function PptIcon(): ReactElement {
  const pathId = `pattern-id-${generateUid()}`
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${pathId})`}>
        <path
          d="M13.2828 1.76001C10.5548 1.76001 8.0949 2.94801 6.4009 4.84001H7.63754C9.02315 3.56508 10.8431 2.76606 12.8428 2.66149V11C12.8428 11.1167 12.8891 11.2286 12.9717 11.3111C13.0542 11.3936 13.1661 11.44 13.2828 11.44H21.6204C21.3897 15.8461 17.7455 19.36 13.2828 19.36C11.1092 19.36 9.12474 18.5284 7.63754 17.16H6.4009C8.0949 19.052 10.5548 20.24 13.2828 20.24C18.378 20.24 22.5228 16.0952 22.5228 11C22.5228 5.90481 18.378 1.76001 13.2828 1.76001ZM13.7228 2.66235C17.9822 2.88538 21.3972 6.30067 21.6204 10.56H13.7228V2.66235ZM2.81043 5.72001C2.03802 5.72001 1.40277 6.35526 1.40277 7.12767V14.8724C1.40277 15.6448 2.03802 16.28 2.81043 16.28H10.5551C11.3275 16.28 11.9628 15.6448 11.9628 14.8724V7.12767C11.9628 6.35526 11.3275 5.72001 10.5551 5.72001H2.81043ZM2.81043 6.60001H10.5551C10.8519 6.60001 11.0828 6.83087 11.0828 7.12767V14.8724C11.0828 15.1691 10.8519 15.4 10.5551 15.4H2.81043C2.51363 15.4 2.28277 15.1691 2.28277 14.8724V7.12767C2.28277 6.83087 2.51363 6.60001 2.81043 6.60001ZM4.86691 8.36001V13.6503H5.93769V11.7872H7.14254C8.13518 11.7872 8.93949 10.9829 8.93949 9.99024V9.95931C8.93861 9.07623 8.22199 8.36001 7.33847 8.36001H4.86691ZM5.93769 9.19446H6.99043C7.44495 9.19446 7.81371 9.56322 7.81371 10.0177V10.1303C7.81371 10.5848 7.44495 10.9536 6.99043 10.9536H5.93769V9.19446Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="22" height="22" fill="white" transform="translate(0.962769)" />
        </clipPath>
      </defs>
    </svg>
  )
}
