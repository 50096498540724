export default function TabletWithSpeakerIcon(): JSX.Element {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8441 47.5902H12.1448C11.8629 47.5902 11.646 47.3516 11.646 47.0914V11.0691C11.646 10.7871 11.8629 10.5703 12.1448 10.5703H37.8441C38.126 10.5703 38.3429 10.7871 38.3429 11.0691V18.2258C38.3429 18.5077 38.126 18.7246 37.8441 18.7246C37.5838 18.7246 37.3453 18.5077 37.3453 18.2258V11.5679H12.6436V46.5926H37.3453V29.5248C37.3453 29.2646 37.5838 29.0477 37.8441 29.0477C38.126 29.0477 38.3429 29.2646 38.3429 29.5248V47.0914C38.3429 47.3516 38.126 47.5902 37.8441 47.5902Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8441 42.407H12.1448C11.8629 42.407 11.646 42.1901 11.646 41.9298C11.646 41.6479 11.8629 41.431 12.1448 41.431H37.8441C38.126 41.431 38.3429 41.6479 38.3429 41.9298C38.3429 42.1901 38.126 42.407 37.8441 42.407Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2559 15.0812H20.7329C20.5594 15.0812 20.4076 14.9944 20.3208 14.8426L18.1521 11.3293C18.0654 11.1775 18.0437 10.9823 18.1304 10.8305C18.2172 10.6787 18.3907 10.5703 18.5642 10.5703H31.4246C31.5981 10.5703 31.7716 10.6787 31.8584 10.8305C31.9451 10.9823 31.9234 11.1775 31.8367 11.3293L29.668 14.8426C29.5812 14.9944 29.4294 15.0812 29.2559 15.0812ZM21.0148 14.0836H28.974L30.5355 11.5679H19.4533L21.0148 14.0836Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6101 13.3245H23.3787C23.0968 13.3245 22.8799 13.086 22.8799 12.8257C22.8799 12.5438 23.0968 12.3269 23.3787 12.3269H26.6101C26.892 12.3269 27.1089 12.5438 27.1089 12.8257C27.1089 13.086 26.892 13.3245 26.6101 13.3245Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7595 44.9877H22.2293C21.9691 44.9877 21.7522 44.7709 21.7522 44.5106C21.7522 44.2287 21.9691 44.0118 22.2293 44.0118H27.7595C28.0198 44.0118 28.2367 44.2287 28.2367 44.5106C28.2367 44.7709 28.0198 44.9877 27.7595 44.9877Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2101 33.0381C30.1017 33.0381 29.9932 32.9947 29.9065 32.9297C29.733 32.7996 29.6896 32.5827 29.7547 32.3875L30.5354 30.0236H30.0149C29.733 30.0236 29.5161 29.8067 29.5161 29.5248V18.2258C29.5161 17.9656 29.733 17.7487 30.0149 17.7487H45.6947C45.955 17.7487 46.1718 17.9656 46.1718 18.2258V29.5248C46.1718 29.8067 45.955 30.0236 45.6947 30.0236H35.762L30.4487 32.9731C30.3836 33.0164 30.2969 33.0381 30.2101 33.0381ZM30.492 29.0477H31.2294C31.3812 29.0477 31.533 29.1127 31.6198 29.2429C31.7282 29.373 31.7499 29.5465 31.6848 29.6983L31.0776 31.4983L35.3933 29.1127C35.4801 29.0694 35.5451 29.0477 35.6319 29.0477H45.1959V18.7246H30.492V29.0477Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6243 36.443H16.0049C15.7447 36.443 15.5278 36.2261 15.5278 35.9659V30.9995C15.5278 30.7176 15.7447 30.5007 16.0049 30.5007H20.6243C20.9062 30.5007 21.1231 30.7176 21.1231 30.9995V35.9659C21.1231 36.2261 20.9062 36.443 20.6243 36.443ZM16.5038 35.4671H20.1255V31.4767H16.5038V35.4671Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7859 38.5467C25.7209 38.5467 25.6558 38.525 25.5907 38.5033L20.4509 36.4213C20.1907 36.3129 20.0605 36.0309 20.169 35.7707C20.2774 35.5321 20.5593 35.402 20.8196 35.5105L25.2871 37.3105V29.6332L20.8196 31.455C20.5593 31.5417 20.2774 31.4333 20.169 31.173C20.0605 30.9128 20.1907 30.6308 20.4509 30.5441L25.5907 28.4404C25.7426 28.3754 25.9161 28.3971 26.0679 28.4838C26.198 28.5923 26.2847 28.7441 26.2847 28.8959V38.0478C26.2847 38.2213 26.198 38.3732 26.0679 38.4599C25.9811 38.525 25.8727 38.5467 25.7859 38.5467Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4124 35.684H25.7859C25.504 35.684 25.2871 35.4454 25.2871 35.1851V31.7586C25.2871 31.4983 25.504 31.2815 25.7859 31.2815H27.4124C27.6944 31.2815 27.9113 31.4983 27.9113 31.7586V35.1851C27.9113 35.4454 27.6944 35.684 27.4124 35.684ZM26.2847 34.6863H26.9136V32.2574H26.2847V34.6863Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1281 40.9756H17.5232C17.2413 40.9756 17.0244 40.7588 17.0244 40.4768V35.9659C17.0244 35.684 17.2413 35.4671 17.5232 35.4671H19.1281C19.3883 35.4671 19.6052 35.684 19.6052 35.9659V40.4768C19.6052 40.7588 19.3883 40.9756 19.1281 40.9756ZM18.022 39.9997H18.6293V36.443H18.022V39.9997Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0051 34.9683H14.552C14.2701 34.9683 14.0532 34.7514 14.0532 34.4912V32.4743C14.0532 32.1923 14.2701 31.9755 14.552 31.9755H16.0051C16.287 31.9755 16.5039 32.1923 16.5039 32.4743V34.4912C16.5039 34.7514 16.287 34.9683 16.0051 34.9683ZM15.0291 33.9924H15.5279V32.9514H15.0291V33.9924Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.48 26.7705H15.4846C15.2244 26.7705 14.9858 26.5537 14.9858 26.2717V18.2258C14.9858 17.9656 15.2244 17.7487 15.4846 17.7487H26.48C26.7403 17.7487 26.9571 17.9656 26.9571 18.2258V26.2717C26.9571 26.5537 26.7403 26.7705 26.48 26.7705ZM15.9834 25.7946H25.9812V18.7246H15.9834V25.7946Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.345 25.2958C19.2582 25.2958 19.1932 25.2958 19.1281 25.2524C18.9546 25.1657 18.8462 24.9922 18.8462 24.8187V19.7005C18.8462 19.5053 18.9546 19.3318 19.1281 19.2451C19.3016 19.18 19.4968 19.2017 19.6486 19.3102L22.9234 21.8692C23.0535 21.956 23.1186 22.1078 23.1186 22.2596C23.1186 22.4114 23.0535 22.5415 22.9234 22.65L19.6486 25.2091C19.5619 25.2741 19.4534 25.2958 19.345 25.2958ZM19.8221 20.6981V23.7994L21.8173 22.2596L19.8221 20.6981Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.9406 21.4789H32.7693C32.4874 21.4789 32.2705 21.262 32.2705 20.9801C32.2705 20.7198 32.4874 20.503 32.7693 20.503H42.9406C43.2008 20.503 43.4177 20.7198 43.4177 20.9801C43.4177 21.262 43.2008 21.4789 42.9406 21.4789Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.9406 24.385H32.7693C32.4874 24.385 32.2705 24.1464 32.2705 23.8862C32.2705 23.6042 32.4874 23.3874 32.7693 23.3874H42.9406C43.2008 23.3874 43.4177 23.6042 43.4177 23.8862C43.4177 24.1464 43.2008 24.385 42.9406 24.385Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.9406 27.2693H32.7693C32.4874 27.2693 32.2705 27.0525 32.2705 26.7705C32.2705 26.5103 32.4874 26.2934 32.7693 26.2934H42.9406C43.2008 26.2934 43.4177 26.5103 43.4177 26.7705C43.4177 27.0525 43.2008 27.2693 42.9406 27.2693Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6753 39.8479H29.2559C28.974 39.8479 28.7571 39.631 28.7571 39.3708C28.7571 39.0888 28.974 38.8719 29.2559 38.8719H35.6753C35.9355 38.8719 36.1524 39.0888 36.1524 39.3708C36.1524 39.631 35.9355 39.8479 35.6753 39.8479Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6753 37.7442H31.4246C31.1427 37.7442 30.9258 37.5274 30.9258 37.2671C30.9258 36.9852 31.1427 36.7683 31.4246 36.7683H35.6753C35.9355 36.7683 36.1524 36.9852 36.1524 37.2671C36.1524 37.5274 35.9355 37.7442 35.6753 37.7442Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6754 35.6623H32.856C32.5958 35.6623 32.3789 35.4237 32.3789 35.1635C32.3789 34.8815 32.5958 34.6647 32.856 34.6647H35.6754C35.9356 34.6647 36.1525 34.8815 36.1525 35.1635C36.1525 35.4237 35.9356 35.6623 35.6754 35.6623Z"
        fill="#1C1C1E"
      />
    </svg>
  )
}
