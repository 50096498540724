export default function FacebookIcon(): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14212_228766)">
        <rect width="24" height="24" rx="12" fill="#444444" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.189 7.77507C14.815 7.70027 14.3099 7.64439 13.9922 7.64439C13.132 7.64439 13.0761 8.01839 13.0761 8.61679V9.68203H15.2264L15.039 11.8886H13.0761V18.6004H10.3838V11.8886H9V9.68203H10.3838V8.31715C10.3838 6.44759 11.2625 5.40039 13.4686 5.40039C14.2351 5.40039 14.7961 5.51259 15.5252 5.66219L15.189 7.77507Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14212_228766">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
