export default function VideoPlayerIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1511_22700)">
        <path
          d="M20.9628 24H4.96277C2.76277 24 0.962769 22.2 0.962769 20V4C0.962769 1.8 2.76277 0 4.96277 0H20.9628C23.1628 0 24.9628 1.8 24.9628 4V20C24.9628 22.2 23.1628 24 20.9628 24ZM4.96277 2C3.86277 2 2.96277 2.9 2.96277 4V20C2.96277 21.1 3.86277 22 4.96277 22H20.9628C22.0628 22 22.9628 21.1 22.9628 20V4C22.9628 2.9 22.0628 2 20.9628 2H4.96277Z"
          fill="black"
        />
        <path
          d="M9.96277 17C9.76277 17 9.66277 17 9.46277 16.9C9.16277 16.7 8.96277 16.4 8.96277 16V8.00001C8.96277 7.60001 9.16277 7.30001 9.46277 7.10001C9.76277 6.90001 10.1628 6.90001 10.4628 7.10001L17.4628 11.1C17.7628 11.3 17.9628 11.6 17.9628 12C17.9628 12.4 17.7628 12.7 17.4628 12.9L10.4628 16.9C10.2628 17 10.1628 17 9.96277 17ZM10.9628 9.70001V14.3L14.9628 12L10.9628 9.70001Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1511_22700">
          <rect width="24" height="24" fill="white" transform="translate(0.962769)" />
        </clipPath>
      </defs>
    </svg>
  )
}
