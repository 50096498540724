export default function IdeaIcon(): JSX.Element {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3622 28.7229H27.4778C27.2176 28.7229 26.979 28.506 26.979 28.2457C26.979 27.9638 27.2176 27.7469 27.4778 27.7469H29.8634V26.4891H27.4778C27.2176 26.4891 26.979 26.2722 26.979 26.012V23.7782C26.979 23.4963 27.2176 23.2794 27.4778 23.2794H30.3622C30.6224 23.2794 30.8393 23.4963 30.8393 23.7782C30.8393 24.0384 30.6224 24.2553 30.3622 24.2553H27.9766V25.5132H30.3622C30.6224 25.5132 30.8393 25.73 30.8393 26.012V28.2457C30.8393 28.506 30.6224 28.7229 30.3622 28.7229Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9092 30.3277C28.649 30.3277 28.4321 30.0892 28.4321 29.8289V28.2458C28.4321 27.9638 28.649 27.7469 28.9092 27.7469C29.1912 27.7469 29.408 27.9638 29.408 28.2458V29.8289C29.408 30.0892 29.1912 30.3277 28.9092 30.3277Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9092 24.2553C28.649 24.2553 28.4321 24.0384 28.4321 23.7782V22.1733C28.4321 21.9131 28.649 21.6962 28.9092 21.6962C29.1912 21.6962 29.408 21.9131 29.408 22.1733V23.7782C29.408 24.0384 29.1912 24.2553 28.9092 24.2553Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4199 42.8412H24.4197C24.1595 42.8412 23.9209 42.6243 23.9209 42.3641V40.1954C23.9209 39.9134 24.1595 39.6966 24.4197 39.6966H33.4199C33.6801 39.6966 33.897 39.9134 33.897 40.1954V42.3641C33.897 42.6243 33.6801 42.8412 33.4199 42.8412ZM24.9185 41.8653H32.9211V40.6725H24.9185V41.8653Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4874 45.14H25.3523C25.0704 45.14 24.8535 44.9232 24.8535 44.6629V42.3641C24.8535 42.0821 25.0704 41.8653 25.3523 41.8653H32.4874C32.7476 41.8653 32.9645 42.0821 32.9645 42.3641V44.6629C32.9645 44.9232 32.7476 45.14 32.4874 45.14ZM25.8511 44.1641H31.9886V42.8412H25.8511V44.1641Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9089 47.569C27.4342 47.569 26.1763 46.4196 25.8293 44.7497C25.8076 44.5979 25.8293 44.4461 25.9378 44.3376C26.0245 44.2292 26.1546 44.1641 26.3065 44.1641H31.5114C31.6632 44.1641 31.815 44.2292 31.9017 44.3376C31.9885 44.4461 32.0319 44.5979 32.0102 44.7497C31.6632 46.4196 30.3836 47.569 28.9089 47.569ZM26.9788 45.14C27.3474 46.0292 28.0848 46.5931 28.9089 46.5931C29.733 46.5931 30.4921 46.0292 30.8607 45.14H26.9788Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9092 32.5398C25.3091 32.5398 22.3813 29.6121 22.3813 26.012C22.3813 22.4119 25.3091 19.4842 28.9092 19.4842C32.5092 19.4842 35.437 22.4119 35.437 26.012C35.437 29.6121 32.5092 32.5398 28.9092 32.5398ZM28.9092 20.4601C25.8513 20.4601 23.379 22.9541 23.379 26.012C23.379 29.0699 25.8513 31.5422 28.9092 31.5422C31.9671 31.5422 34.4611 29.0699 34.4611 26.012C34.4611 22.9541 31.9671 20.4601 28.9092 20.4601Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.591 40.6725H23.2486C22.9884 40.6725 22.7498 40.4556 22.7498 40.1954C22.7498 35.0772 21.5354 33.2121 20.4727 31.5856C19.5618 30.1976 18.6943 28.8747 18.6943 25.9686C18.6943 20.33 23.2703 15.754 28.909 15.754C34.5476 15.754 39.1453 20.33 39.1453 25.9686C39.1453 28.8747 38.2778 30.1976 37.367 31.5856C36.2826 33.2121 35.0681 35.0772 35.0681 40.1954C35.0681 40.4556 34.8513 40.6725 34.591 40.6725ZM23.7474 39.6966H34.0922C34.179 34.6435 35.4151 32.735 36.5428 31.0434C37.4103 29.7205 38.1694 28.5711 38.1694 25.9686C38.1694 20.8721 34.0055 16.7299 28.909 16.7299C23.8125 16.7299 19.6703 20.8721 19.6703 25.9686C19.6703 28.5711 20.4293 29.7205 21.2968 31.0434C22.4028 32.735 23.6607 34.6435 23.7474 39.6966Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9088 14.0624C28.6485 14.0624 28.4316 13.8455 28.4316 13.5853V11.0695C28.4316 10.7876 28.6485 10.5707 28.9088 10.5707C29.1907 10.5707 29.4076 10.7876 29.4076 11.0695V13.5853C29.4076 13.8455 29.1907 14.0624 28.9088 14.0624Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7061 15.7323C22.5326 15.7323 22.3591 15.6455 22.2724 15.4937L21.0145 13.325C20.8844 13.0865 20.9712 12.7828 21.2097 12.631C21.4266 12.5009 21.7302 12.5877 21.882 12.8262L23.1182 14.9949C23.27 15.2335 23.1833 15.5371 22.9447 15.6672C22.8796 15.7106 22.7929 15.7323 22.7061 15.7323Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1519 20.2866C18.0651 20.2866 17.9784 20.2649 17.9133 20.2215L15.7229 18.9637C15.506 18.8335 15.4193 18.5299 15.5494 18.2913C15.6795 18.0528 15.9832 17.966 16.2217 18.1179L18.3904 19.354C18.629 19.5058 18.7157 19.8094 18.5856 20.048C18.4989 20.1998 18.3254 20.2866 18.1519 20.2866Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.1331 15.7323C35.0463 15.7323 34.9596 15.7106 34.8728 15.6672C34.6559 15.5371 34.5692 15.2335 34.6993 14.9949L35.9572 12.8262C36.0873 12.5877 36.3909 12.5009 36.6295 12.631C36.868 12.7828 36.9548 13.0865 36.803 13.325L35.5451 15.4937C35.4584 15.6455 35.3066 15.7323 35.1331 15.7323Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6871 20.2866C39.5136 20.2866 39.3401 20.1998 39.2534 20.048C39.1233 19.8094 39.1883 19.5058 39.4269 19.354L41.5956 18.1179C41.8342 17.966 42.1378 18.0528 42.2896 18.2913C42.4197 18.5299 42.333 18.8335 42.0944 18.9637L39.9257 20.2215C39.8389 20.2649 39.7522 20.2866 39.6871 20.2866Z"
        fill="#64D8DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8512 26.4891H41.3355C41.0753 26.4891 40.8584 26.2722 40.8584 26.012C40.8584 25.7301 41.0753 25.5132 41.3355 25.5132H43.8512C44.1332 25.5132 44.35 25.7301 44.35 26.012C44.35 26.2722 44.1332 26.4891 43.8512 26.4891Z"
        fill="#64D8DF"
        stroke="#64D8DF"
        strokeWidth="0.287963"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4821 26.4891H13.9881C13.7061 26.4891 13.4893 26.2722 13.4893 26.012C13.4893 25.7301 13.7061 25.5132 13.9881 25.5132H16.4821C16.764 25.5132 16.9809 25.7301 16.9809 26.012C16.9809 26.2722 16.764 26.4891 16.4821 26.4891Z"
        fill="#64D8DF"
      />
    </svg>
  )
}
