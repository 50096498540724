/**
 * Generate random UID
 * CHAT GPT GENERATED FUNCTION
 */
export default function generateUid() {
  let uuid = ''
  const characters = 'abcdef0123456789'
  for (let i = 0; i < 36; i++) {
    if (i === 8 || i === 13 || i === 18 || i === 23) {
      uuid += '-'
    } else if (i === 14) {
      uuid += '4'
    } else if (i === 19) {
      uuid += characters.charAt(Math.floor(Math.random() * characters.length))
    } else {
      uuid += characters.charAt(Math.floor(Math.random() * characters.length))
    }
  }
  return uuid
}
