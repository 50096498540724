import TabletWithSpeakerIcon from '@features/_ui/icons/tablet-with-speaker'
import PeopleThinkingIcon from '@features/_ui/icons/people-thinking'
import GraphGrowingIcon from '@features/_ui/icons/graph-growing'
import IdeaIcon from '@features/_ui/icons/idea'
import FacebookIcon from '@features/_ui/icons/facebook'
import InstagramIcon from '@features/_ui/icons/instagram'
import TwitterIcon from '@features/_ui/icons/twitter'
import { IconsEnum } from '@shared/enums/layout/icons.enum'
import { createElement, ReactElement } from 'react'
import LinkedinIcon from '@features/_ui/icons/linkedin'
import PdfIcon from '@ui/icons/pdf'
import WordIcon from '@ui/icons/word'
import ExcelIcon from '@ui/icons/excel'
import PptIcon from '@ui/icons/ppt'
import LinkIcon from '@ui/icons/link'
import UnknownFileIcon from '@ui/icons/unknown-file'
import VideoPlayerIcon from '@ui/icons/video-player'

const IconComponents = {
  [IconsEnum.TABLET_WITH_SPEAKER]: TabletWithSpeakerIcon,
  [IconsEnum.PEOPLE_THINKING]: PeopleThinkingIcon,
  [IconsEnum.GRAPH_GROWING_ICON]: GraphGrowingIcon,
  [IconsEnum.IDEA_ICON]: IdeaIcon,
  [IconsEnum.FACEBOOK]: FacebookIcon,
  [IconsEnum.INSTAGRAM]: InstagramIcon,
  [IconsEnum.TWITTER]: TwitterIcon,
  [IconsEnum.LINKEDIN]: LinkedinIcon,
  [IconsEnum.WORD]: WordIcon,
  [IconsEnum.EXCEL]: ExcelIcon,
  [IconsEnum.PPT]: PptIcon,
  [IconsEnum.EXCEL]: ExcelIcon,
  [IconsEnum.PDF]: PdfIcon,
  [IconsEnum.LINK]: LinkIcon,
  [IconsEnum.VIDEO]: VideoPlayerIcon,
  [IconsEnum.UNKNOWN]: UnknownFileIcon,
}

export interface Props {
  icon: IconsEnum
}

export default function IconsRender({ icon }: Props): ReactElement {
  if (typeof IconComponents[icon] !== 'undefined') {
    return createElement(IconComponents[icon])
  }
  return <p style={{ color: 'red' }}>Icon {icon} not defined.</p>
}
