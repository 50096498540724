export default function LinkedinIcon(): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_210_1897)">
        <rect x="6" y="6" width="13" height="12" fill="white" />
        <path
          d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM10 16H8V10H10V16ZM9 9.109C8.393 9.109 7.9 8.613 7.9 8C7.9 7.388 8.392 6.891 9 6.891C9.608 6.891 10.1 7.388 10.1 8C10.1 8.613 9.607 9.109 9 9.109ZM17 16H15.002V13.139C15.002 11.258 13 11.417 13 13.139V16H11V10H13V11.093C13.872 9.477 17 9.357 17 12.641V16Z"
          fill="#444444"
        />
      </g>
      <defs>
        <clipPath id="clip0_210_1897">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
