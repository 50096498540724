import { DirectionsEnum } from '@shared/enums/layout/directions.enum'
import styles from './styles.module.scss'

export interface Props {
  direction: DirectionsEnum
  colour?: string
}
export default function ChevronIcon({ direction, colour }: Props): JSX.Element {
  const chevronStyles = [styles.chevron, styles[direction]].join(' ')
  return (
    <svg
      className={chevronStyles}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7526 3.34157C16.3889 2.92593 15.7572 2.88382 15.3415 3.2475L5 12.0001L15.3415 20.7526C15.7571 21.1163 16.3889 21.0742 16.7526 20.6586C17.1163 20.2429 17.0741 19.6112 16.6585 19.2475L8 12.0001L16.6586 4.75265C17.0742 4.38897 17.1163 3.75721 16.7526 3.34157Z"
        fill={colour ?? 'black'}
      />
    </svg>
  )
}
