import { ReactElement } from 'react'
import generateUid from '@utils/generate-uid'

export default function ExcelIcon(): ReactElement {
  const pathId = `pattern-id-${generateUid()}`

  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${pathId})`}>
        <path
          d="M13.6677 -3.69219e-06C13.6591 0.00343381 13.6488 0.00859008 13.6402 0.0137463L1.32019 2.35125C1.1105 2.39078 0.959251 2.5764 0.962689 2.79125V19.2087C0.959251 19.4236 1.1105 19.6092 1.32019 19.6487L13.6402 21.9862C13.7674 22.0103 13.8997 21.9777 14.0011 21.8952C14.1008 21.8127 14.161 21.6906 14.1627 21.56V19.36H21.6427C22.1239 19.36 22.5227 18.9612 22.5227 18.48V3.52C22.5227 3.03875 22.1239 2.64 21.6427 2.64H14.1627V0.439996C14.1644 0.312809 14.1094 0.192496 14.0149 0.108278C13.9203 0.0240588 13.7932 -0.0154725 13.6677 -3.69219e-06ZM13.2827 0.962496V2.87375C13.2243 2.99578 13.2243 3.13671 13.2827 3.25875V18.8375C13.2707 18.8959 13.2707 18.9578 13.2827 19.0162V21.0375L1.84269 18.8512V3.14875L13.2827 0.962496ZM14.1627 3.52H21.6427V18.48H14.1627V16.28H15.9227V15.4H14.1627V12.76H15.9227V11.88H14.1627V9.68H15.9227V8.8H14.1627V6.6H15.9227V5.72H14.1627V3.52ZM16.8027 5.72V6.6H20.3227V5.72H16.8027ZM3.90519 6.9025L6.31144 11.0137L3.68519 15.125H5.88519L7.31519 12.4712C7.41488 12.2152 7.48019 12.0192 7.50769 11.8937H7.52144C7.57816 12.1619 7.63316 12.3441 7.68644 12.4437L9.11644 15.125H11.3027L8.77269 10.9725L11.2339 6.9025H9.17144L7.86519 9.33625C7.73972 9.65421 7.65035 9.90515 7.60394 10.065H7.59019C7.518 9.79687 7.4355 9.55796 7.34269 9.36375L6.16019 6.9025H3.90519ZM16.8027 8.8V9.68H20.3227V8.8H16.8027ZM16.8027 11.88V12.76H20.3227V11.88H16.8027ZM16.8027 15.4V16.28H20.3227V15.4H16.8027Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id={pathId}>
          <rect width="22" height="22" fill="white" transform="translate(0.962769)" />
        </clipPath>
      </defs>
    </svg>
  )
}
