export default function GraphGrowingIcon(): JSX.Element {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.9308 46.8531H10.9085C10.6265 46.8531 10.4097 46.6146 10.4097 46.3543V43.535C10.4097 43.2748 10.6265 43.0362 10.9085 43.0362H46.9308C47.1911 43.0362 47.4296 43.2748 47.4296 43.535V46.3543C47.4079 46.6146 47.1911 46.8531 46.9308 46.8531ZM11.4073 45.8555H46.432V44.0338H11.4073V45.8555Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.412 44.0338H13.9879C13.7276 44.0338 13.5107 43.8169 13.5107 43.535V33.0818C13.5107 32.7998 13.7276 32.583 13.9879 32.583H18.412C18.6723 32.583 18.8892 32.7998 18.8892 33.0818V43.535C18.8892 43.8169 18.6723 44.0338 18.412 44.0338ZM14.4867 43.0362H17.9132V33.5806H14.4867V43.0362Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.892 44.0338H22.4678C22.2076 44.0338 21.9907 43.8169 21.9907 43.535V26.4672C21.9907 26.2069 22.2076 25.9684 22.4678 25.9684H26.892C27.1523 25.9684 27.3691 26.2069 27.3691 26.4672V43.535C27.3691 43.8169 27.1523 44.0338 26.892 44.0338ZM22.9666 43.0362H26.3932V26.966H22.9666V43.0362Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.35 44.0338H30.9475C30.6873 44.0338 30.4487 43.8169 30.4487 43.535V30.6528C30.4487 30.3709 30.6873 30.154 30.9475 30.154H35.35C35.632 30.154 35.8488 30.3709 35.8488 30.6528V43.535C35.8488 43.8169 35.632 44.0338 35.35 44.0338ZM31.4463 43.0362H34.8729V31.1516H31.4463V43.0362Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8295 44.0338H39.427C39.1451 44.0338 38.9282 43.8169 38.9282 43.535V20.6984C38.9282 20.4382 39.1451 20.2213 39.427 20.2213H43.8295C44.1114 20.2213 44.3283 20.4382 44.3283 20.6984V43.535C44.3283 43.8169 44.1114 44.0338 43.8295 44.0338ZM39.9258 43.0362H43.3307V21.1972H39.9258V43.0362Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5015 26.0985C17.328 26.0985 17.1762 26.0118 17.0678 25.8383C16.9376 25.5997 17.0244 25.3178 17.263 25.166L23.357 21.6527C23.5956 21.5225 23.8992 21.5876 24.0293 21.8262C24.1595 22.0647 24.0944 22.3683 23.8558 22.4985L17.7401 26.0335C17.675 26.0768 17.5883 26.0985 17.5015 26.0985ZM31.8367 23.7997C31.7933 23.7997 31.75 23.7997 31.7066 23.778L25.9161 22.2382C25.6559 22.1515 25.5041 21.8912 25.5691 21.631C25.6342 21.3707 25.8944 21.2189 26.1764 21.284L31.9668 22.8238C32.2271 22.9105 32.3789 23.1708 32.3138 23.431C32.2488 23.6479 32.0536 23.7997 31.8367 23.7997ZM34.0488 23.2141C33.9187 23.2141 33.8102 23.1491 33.7018 23.0623C33.5066 22.8671 33.5066 22.5635 33.7018 22.3683L40.1863 15.8839C40.3814 15.6887 40.6851 15.6887 40.8802 15.8839C41.0754 16.0791 41.0754 16.3827 40.8802 16.5779L34.3958 23.0623C34.309 23.1491 34.1789 23.2141 34.0488 23.2141Z"
        fill="#84EEF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4872 17.0983C40.468 17.0983 39.6655 16.2959 39.6655 15.2983C39.6655 14.279 40.468 13.4766 41.4872 13.4766C42.4849 13.4766 43.309 14.279 43.309 15.2983C43.2873 16.2959 42.4849 17.0983 41.4872 17.0983ZM41.4872 14.4525C41.0101 14.4525 40.6414 14.8212 40.6414 15.2983C40.6414 15.7537 41.0101 16.1224 41.4872 16.1224C41.9427 16.1224 42.3114 15.7537 42.3114 15.2983C42.3114 14.8212 41.9427 14.4525 41.4872 14.4525Z"
        fill="#84EEF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1162 25.4696C32.1185 25.4696 31.2944 24.6455 31.2944 23.6479C31.2944 22.6503 32.1185 21.8261 33.1162 21.8261C34.1138 21.8261 34.9379 22.6503 34.9379 23.6479C34.9379 24.6455 34.1138 25.4696 33.1162 25.4696ZM33.1162 22.8237C32.6607 22.8237 32.2704 23.1924 32.2704 23.6479C32.2704 24.1033 32.6607 24.4937 33.1162 24.4937C33.5716 24.4937 33.9403 24.1033 33.9403 23.6479C33.9403 23.1924 33.5716 22.8237 33.1162 22.8237Z"
        fill="#84EEF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7665 23.2358C23.7472 23.2358 22.9448 22.4117 22.9448 21.4141C22.9448 20.4165 23.7472 19.5924 24.7665 19.5924C25.7642 19.5924 26.5666 20.4165 26.5666 21.4141C26.5666 22.4117 25.7642 23.2358 24.7665 23.2358ZM24.7665 20.5683C24.2894 20.5683 23.9207 20.9587 23.9207 21.4141C23.9207 21.8695 24.2894 22.2382 24.7665 22.2382C25.222 22.2382 25.5907 21.8695 25.5907 21.4141C25.5907 20.9587 25.222 20.5683 24.7665 20.5683Z"
        fill="#84EEF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.352 28.0937C15.3544 28.0937 14.5303 27.2696 14.5303 26.272C14.5303 25.2527 15.3544 24.4503 16.352 24.4503C17.3496 24.4503 18.1737 25.2527 18.1737 26.272C18.1737 27.2696 17.3496 28.0937 16.352 28.0937ZM16.352 25.4262C15.8966 25.4262 15.5279 25.7949 15.5279 26.272C15.5279 26.7274 15.8966 27.0961 16.352 27.0961C16.8074 27.0961 17.1978 26.7274 17.1978 26.272C17.1978 25.7949 16.8074 25.4262 16.352 25.4262Z"
        fill="#84EEF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0458 22.4117C14.4 22.4117 12.2529 20.2647 12.2529 17.6405C12.2529 14.9947 14.4 12.8477 17.0458 12.8477C17.306 12.8477 17.5446 13.0646 17.5446 13.3248V17.1417H21.3398C21.6001 17.1417 21.8386 17.3586 21.8386 17.6405C21.8386 20.2647 19.6916 22.4117 17.0458 22.4117ZM16.547 13.867C14.6819 14.1055 13.2505 15.7104 13.2505 17.6405C13.2505 19.7225 14.9421 21.4358 17.0458 21.4358C18.9759 21.4358 20.5808 19.9828 20.8194 18.1177H17.0458C16.7639 18.1177 16.547 17.9008 16.547 17.6405V13.867Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8798 16.5779H18.5857C18.3038 16.5779 18.0869 16.361 18.0869 16.1007V11.8067C18.0869 11.5248 18.3038 11.3079 18.5857 11.3079C21.1882 11.3079 23.3135 13.4115 23.3569 16.014C23.3786 16.0357 23.3786 16.0791 23.3786 16.1007C23.3786 16.361 23.1617 16.5779 22.8798 16.5779ZM19.0628 15.6019H22.3593C22.1207 13.9103 20.7761 12.5441 19.0628 12.3272V15.6019Z"
        fill="#BD67FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1519 22.2816C17.935 22.2816 17.7398 22.1298 17.6748 21.9129L16.5687 17.749C16.5037 17.4887 16.6555 17.2285 16.9157 17.1634C17.176 17.0767 17.4579 17.2502 17.523 17.5104L18.629 21.6526C18.6941 21.9129 18.5423 22.1948 18.282 22.2599C18.2386 22.2599 18.1953 22.2816 18.1519 22.2816Z"
        fill="#BD67FD"
      />
    </svg>
  )
}
